// import './vendors/jqueryCookie';
import './vendors/doubleTapToGo';
import './vendors/StickyKit';
import GumShoe from './vendors/GumShoe';
import Swiper from './vendors/swiper';

( function( $ ) {

    $( document ).ready( function() {
        /**
         * Sticky menu
         */
        $(function () {
            var $header = $('.header_inner'),
                $topHeader = $header.find('.header_top');

            var stickIt = function () {
                //if ($(window).width() >= 1000) {
                    var topHeaderHeight = $topHeader.height();

                    if ($('.header_inner.cloned').length < 1) {
                        $header
                            .addClass('original')
                            .clone(true)
                            .insertAfter($header)
                            .addClass('cloned')
                            .css({
                                'position': 'fixed',
                                'top': 0
                                // 'top': parseInt(topHeaderHeight) * -1
                            })
                            .removeClass('original')
                            .hide();
                    }

                    var $elOriginal, $elCloned, elementPos, elementTop;

                    $elOriginal = $('.header_inner.original');
                    $elCloned = $('.header_inner.cloned');
                    elementPos = $elOriginal.offset();
                    elementTop = elementPos.top + ($header.height() / 2);

                    if ($(window).scrollTop() >= elementTop) {
                        // Scrolled past the original position

                        $elCloned
                            .addClass('is-sticky')
                            .css({
                                'left': 0,
                                'right': 0
                            })
                            .slideDown(500);
                        $elOriginal.css({
                            'visibility': 'hidden',
                            'position': 'relative',
                            'z-index': -1
                        });
                    } else {
                        // Not scrolled past the original position, show the original menu

                        $elCloned
                            .removeClass('is-sticky')
                            .hide();
                        $elOriginal.removeAttr('style');
                    }
                //} 
                // else {
                //     if ($('.header_inner.cloned').length) $('.header_inner.cloned').remove();
                //     if ($('.header_inner.original').length) {
                //         $('.header_inner.original')
                //             .removeAttr('style')
                //             .removeClass('original');
                //     }
                // }
            }

            $(window).on('load scroll resize', stickIt);
        });

        var headerIsSticky = function () {
          return $('.header_inner.is-sticky .header_bottom').length > 0;
        };


        /**
         ** Sticky image
         **/
        var heightHeaderSticky = 193;
        if( $(".anchorBlock__links .vc_column-inner").length ) {
            $(".anchorBlock__links .vc_column-inner").stick_in_parent({offset_top: heightHeaderSticky});
        }

        if( $(".anchorBlock_container").length && $(window).width() > 1000 ) {
            $(".anchorBlock_container .anchorBlock__links ul").stick_in_parent({offset_top: heightHeaderSticky});
        }

        if( $(".chapo_interne_intervention").length ) {
            $(".chapo_interne_intervention .vc_column_container:eq(0) .vc_column-inner").stick_in_parent({offset_top: heightHeaderSticky});
        }

        if( $(".bloc_chapo").length ) {
            $(".bloc_chapo .vc_column_container:eq(0) .vc_column-inner").stick_in_parent({offset_top: heightHeaderSticky});
        }





        /**
         * Tabs remove function
         */
        $(function () {
            var $tabsRemove = $('.tabs-remove');

            if ($tabsRemove.length > 0) {
                var $tabsNav = $tabsRemove.find('.tabs-nav'),
                    $tabsNavContainerHTML = $('<div class="tabs-nav__container"></div>');

                $tabsNav.addClass('tabs-removeNotSticky');

                // Sticky tabs variables
                $(window).on('load resize', function () {
                    if ($tabsNav.is(':visible')) {
                        var $tabsNavContainer = $('.tabs-nav__container');
                        $tabsNavContainer.removeAttr('style');

                        var tabsRemoveHeight = $tabsRemove.find('.tabs-container').outerHeight(true),
                            tabsNavWidth;

                        if ($tabsNavContainer.parent().length) {
                            tabsNavWidth = $tabsNavContainer.outerWidth();
                            $tabsNavContainer.css({
                                'width': tabsNavWidth,
                                'height': tabsRemoveHeight
                            });
                            $tabsNav.css('width', tabsNavWidth);
                        } else {
                            $tabsNav.wrap($tabsNavContainerHTML);

                            tabsNavWidth = $tabsNavContainer.outerWidth();
                            $tabsNavContainer.css({
                                'width': tabsNavWidth,
                                'height': tabsRemoveHeight
                            });
                            $tabsNav.css('width', tabsNavWidth);
                        }

                        if ($tabsNav.hasClass('tabs-removeIsSticky')) {
                            $tabsNav.css({
                               'position': 'fixed',
                               'top': headerIsSticky() ? $('.header_inner.is-sticky .header_bottom').outerHeight() : 0
                            });
                        } else if ($tabsNav.hasClass('tabs-removeNotSticky')) {
                            $tabsNav.css({
                               'position': 'absolute',
                               'top': 0
                            });
                        } else if ($tabsNav.hasClass('tabs-removeExceededBottom')) {
                            $tabsNav.css({
                               'position': 'absolute',
                               'bottom': 0
                            });
                        }
                    }
                });

                // Sticky tabs scroll comportment
                $(window).on('load resize scroll', function () {
                    if ($tabsNav.is(':visible')) {
                        var scrollTop = $(this).scrollTop(),
                            topDistance = $tabsRemove.offset().top,
                            tabsNavDistanceFromParent = $tabsNav.offset().top - $tabsNav.parent().offset().top - $tabsNav.parent().scrollTop(),
                            dontExceedDistanceFromParent = $tabsNav.parent().outerHeight() - $tabsNav.outerHeight(),
                            $tabsNavContainer = $('.tabs-nav__container');

                        if (scrollTop > (topDistance - (headerIsSticky() ? $('.header_inner.is-sticky .header_bottom').outerHeight() : 0))) {
                            if (!$tabsNav.hasClass('tabs-removeExceededBottom')) {
                                if (tabsNavDistanceFromParent < dontExceedDistanceFromParent) {
                                    // Scrolled past the tabs
                                    if (!$tabsNav.hasClass('tabs-removeIsSticky')) {
                                        $tabsNav.css({
                                            'position': 'fixed',
                                            'top': headerIsSticky() ? $('.header_inner.is-sticky .header_bottom').outerHeight() : 0,
                                            'bottom': 'inherit'
                                        });

                                        $tabsNav.removeClass().addClass('tabs-nav tabs-removeIsSticky');
                                    }
                                } else {
                                    if (!$tabsNav.hasClass('tabs-removeExceededBottom')) {
                                        $tabsNavContainer.css('position', 'relative');
                                        $tabsNav.css({
                                            'position': 'absolute',
                                            'top': 'inherit',
                                            'bottom': 0,
                                        });

                                        $tabsNav.removeClass().addClass('tabs-nav tabs-removeExceededBottom');
                                    }
                                }
                            } else if (scrollTop < (dontExceedDistanceFromParent + $tabsNav.parent().offset().top)) {
                                if (!$tabsNav.hasClass('tabs-removeIsSticky')) {
                                    $tabsNav.css({
                                        'position': 'fixed',
                                        'top': headerIsSticky() ? $('.header_inner.is-sticky .header_bottom').outerHeight() : 0,
                                        'bottom': 'inherit'
                                    });

                                    $tabsNav.removeClass().addClass('tabs-nav tabs-removeIsSticky');
                                }
                            }
                        } else {
                            // Scrolled before the tabs
                            if (!$tabsNav.hasClass('tabs-removeNotSticky')) {
                                $tabsNav.css({
                                    'position': 'absolute',
                                    'top': 0,
                                    'bottom': 'inherit'
                                });

                                $tabsNav.removeClass().addClass('tabs-nav tabs-removeNotSticky');
                            }
                        }
                    }
                });

                $(window).trigger('resize'); // Bugfix tabs nav and tabs nav container width not setting up

                $tabsRemove.find('.tabs-nav li a').on('click', function (e) {
                    e.preventDefault();
                    $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top - (headerIsSticky() ? $('.header_inner.is-sticky .header_bottom').outerHeight() : 25)}, 500);
                });
            }
        });

        (function ($) {
            $.fn.anchorBlock = function (options) {
                let settings = $.extend({
                    linksContainer: '.anchor-block__links',
                    contentContainer: '.anchor-block__content',
                    useStickInParentPlugin: true
                }, options);

                let el            = $(this),
                    anchors       = {},
                    lastScrollTop = 0,
                    activeLink, activeLinkID, activeContent;

                /**
                 * Is the element is visible in the viewport ?
                 *
                 * @returns {boolean}
                 */
                $.fn.visibleInViewPort = function () {
                    let el              = $(this),
                        topOfElement    = el.offset().top,
                        bottomOfElement = topOfElement + el.outerHeight(),
                        bottomOfScreen  = $(window).scrollTop() + $(window).innerHeight(),
                        topOfScreen     = $(window).scrollTop();

                    if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)) return true;
                    return false;
                };

                /**
                 * Init the anchor
                 */
                let init = () => {
                    if (el.length > 0) {
                        // Sticky links
                        if (settings.useStickInParentPlugin) {
                            el.find($(settings.linksContainer)).stick_in_parent({
                                offset_top: 50
                            });
                            $(window).on('load resize', function () {
                                el.find($(settings.linksContainer)).css('height', 'auto').height(el.find($(settings.linksContainer)).outerHeight());
                            });
                        }

                        // Get anchors in an array
                        getAnchors();

                        // On load comportment
                        $(window).on('load', activateLink);

                        // Scroll comportment
                        $(window).on('scroll', getActualScrolledID);
                    }
                };

                /**
                 * Activate link
                 *
                 * 1 - First one if not in the anchor block
                 * 2 - Good one if on the anchor block
                 */
                let activateLink = () => {
                    if (!el.visibleInViewPort()) {
                        /* 1 - First one if not in the anchor block*/

                        // Activate the first link
                        let firstLink = el.find($(settings.linksContainer)).find('a').first();

                        // Set up some global variables to find out the active link
                        // & the active ID
                        // & the active content element
                        firstLink.parent().addClass('active');
                        activeLink = firstLink;
                        activeLinkID = activeLink.attr('href').replace('#', '');
                        activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);
                    } else if (el.visibleInViewPort()) {
                        el.find($(settings.linksContainer)).find('a').first().parent().removeClass('active');

                        /* 2 - Good one if on the anchor block */

                        let scrollPos = $(window).scrollTop(),
                            positions = {};

                        for (let id in anchors) {
                            let element             = el.find($(settings.contentContainer)).find(`[id="${id}"]`),
                                elementHeight       = element.outerHeight(),
                                elementOffsetTop    = element.offset().top,
                                position            = ((elementOffsetTop - 60)) - scrollPos;

                            positions[id] = position;
                        }

                        let closest = 0,
                            closestID;

                        // Get the closest number to 0 (it is the good one to activate)
                        for (let id in positions) {
                            if (closest === 0) {
                                closest = positions[id];
                                closestID = id;
                            } else if (positions[id] > 0 && positions[id] <= Math.abs(closest)) {
                                closest = positions[id];
                                closestID = id;
                            } else if (positions[id] < 0 && -positions[id] < Math.abs(closest)) {
                                closest = positions[id];
                                closestID = id;
                            }
                        }

                        // Activate the good link
                        let link = anchors[closestID];

                        link.parent().addClass('active');
                        activeLink = link;
                        activeLinkID = closestID;
                        activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                        // Add hash to URL
                        history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                    }
                };

                /**
                 * Get anchors
                 *
                 * stored as [ id => element ]
                 */
                let getAnchors = () => {
                    el.find($(settings.linksContainer)).find('a').each(function () {
                        let ID = $(this).attr('href').replace('#', '');

                        anchors[ID] = $(this);
                    });
                };

                /**
                 * Get next link
                 *
                 * @param ID
                 * @returns {boolean|string|*}
                 */
                let getNextLink = (ID) => {
                    let IDs = Object.keys(anchors),
                        i   = IDs.indexOf(ID),
                        l   = 0;

                    for (let j in anchors) {
                        if (i + 1 === l) return {id: j, link: anchors[j]};
                        l++;
                    }

                    return false;
                };

                /**
                 * Get prev link
                 *
                 * @param ID
                 * @returns {boolean|string|*}
                 */
                let getPrevLink = (ID) => {
                    let IDs = Object.keys(anchors),
                        i   = IDs.indexOf(ID) - 1,
                        l   = 0;

                    for (let j in anchors) {
                        if (i === l) return {id: j, link: anchors[j]};
                        l++;
                    }

                    return false;
                };

                /**
                 * Get the actual scrolled item ID
                 */
                let getActualScrolledID = () => {
                    // Check if container has been scrolled past
                    if (el.visibleInViewPort() && activeContent) {
                        let scrollTop             = $(window).scrollTop(),
                            scrollPos             = $(document).scrollTop(),
                            activeContentPosition = activeContent.offset().top - 60,
                            activeContentHeight   = activeContent.outerHeight();

                        if (scrollTop > lastScrollTop) {
                            // Downscroll
                            if (scrollPos >= (activeContentPosition + activeContentHeight)) {
                                // If active element has been passed
                                let nextLink = getNextLink(activeLinkID);

                                if (nextLink !== false) {
                                    activeLink.parent().removeClass('active');

                                    activeLink = nextLink.link;
                                    activeLinkID = nextLink.id;
                                    activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                                    activeLink.parent().addClass('active');

                                    // Add hash to URL
                                    history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                                }
                            }
                        } else {
                            // Upscroll
                            if (scrollPos <= activeContentPosition) {
                                // If active element has been passed
                                let prevLink = getPrevLink(activeLinkID);

                                if (prevLink !== false) {
                                    activeLink.parent().removeClass('active');

                                    activeLink = prevLink.link;
                                    activeLinkID = prevLink.id;
                                    activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                                    activeLink.parent().addClass('active');

                                    // Add hash to URL
                                    history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                                }
                            }

                        }

                        lastScrollTop = scrollTop;
                    }
                };

                init();
            };
        })(jQuery);

        $('.tabs-remove').anchorBlock({
            linksContainer: '.tabs-nav',
            contentContainer: '.tabs-container',
            useStickInParentPlugin: false
        });


        // Slider Cabinet
        let $selectorContainer = $('.text-slider');
        if ($selectorContainer.length) {
            $selectorContainer.each(function () {
              let _this = this;

              let textSlider = new Swiper($(_this).find('.default-carousel'), {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                  loop: true,
                  observer: true,
                  observerParents: true,
                  navigation: {
                    prevEl: _this.querySelector('.text-slider__navigations__left'),
                    nextEl: _this.querySelector('.text-slider__navigations__right')
                  },
                  breakpoints: {
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 1.3,
                        spaceBetween: 20,
                    }
                  },
                  on: {
                    resize: function () {
                        moveNavigations(textSlider, $(_this).find('.text-slider__navigations'));
                    },
                    slideChange: function () {
                        moveNavigations(textSlider, $(_this).find('.text-slider__navigations'));
                    }
                  }
              });
            });
        }
        


        /**
         * Close cookie popup don't trigger the click
         */
        $(function () {
            $('#closeCookie').on('click', function (e) {
                e.preventDefault();
            });
        });

        /**
         * Side menu
         */
        $(function () {
            $('.side_menu_opener').on('click', function () {
                $('body').addClass('side_menu_open1');
            });
            $('.close_side_menu').on('click', function (e) {
                e.preventDefault();
                $('body').removeClass('side_menu_open1');
            });
        });

        /**
         * Block moving alone along others
         */
        $(function () {
            // Home One step, animate a block moving along all others 
            var block = $('<div class="images-animation" />');
            $('#stepOne .q_elements_holder').append(block);

            /**
             * Move the block along item, and adapt it width, height, left & top
             *
             * @param n
             */
            var moveBlock = function (n) {
                var item        = $('#stepOne .q_elements_holder > .q_elements_item:eq('+ n + ')'),
                    block       = $('#stepOne .q_elements_holder .images-animation'),
                    width       = item.width(),
                    height      = item.height(),
                    position    = item.position();
                   
                if( block.length ) {
                    block.css({
                        'width': width,
                        'height': height,
                        'left': position['left'],
                        'top': position['top']
                    });
                }
            };

            /**
             * Call the function to move block with the right block number
             *
             * @type {number}
             */
            var count = 0;
            var incrementItem = function () {
                var items = $('#stepOne .q_elements_holder .q_elements_item').length;

                if (count < items) {
                    moveBlock(count);
                    count++;
                } else {
                    count = 0;
                    moveBlock(count);
                }
            };

            var animateBlock,
                animationTime = 1500;

            incrementItem();
            animateBlock = setInterval(incrementItem, animationTime);

            /**
             * On mouse enter, move the block to the index
             * On mouse leave, set up the animation again
             */
            $('#stepOne .q_elements_holder .q_elements_item').mouseenter(function () {
                clearInterval(animateBlock);

                var index = $(this).index();

                moveBlock(index);
                count = index;
            }).mouseleave(function () {
                animateBlock = setInterval(incrementItem, animationTime);
            });
        });

        /**
         * PARALLAX
         */
        $(function () {

            function parallaxCode(intensity, element, direction = 'vertical') {
                var scrollTop = $(window).scrollTop();
                var imgPos = scrollTop / intensity + 'px';
                if (direction === "vertical") {
                    element.css('transform', 'translateY(' + imgPos + ')');
                } else if (direction === "horizontal") {
                    element.css('transform', 'translateX(' + imgPos + ')');
                }
            }

            /**
             * Activate parallax on document ready
             *
             * @param intensity
             * @param element
             */
            function parallax(intensity, element, direction) {
                $(document).ready(function () {
                    parallaxCode(intensity, element, direction);
                });
                $(window).scroll(function() {
                    parallaxCode(intensity, element, direction);
                });
            }

            parallax(-2, $('.otherStep-parallax'), 'horizontal');
        });

        /**
         *  Step, text title animation
         */
        $(function () {
            function switchClass(i) {
                var lis = $('.container-head-step > div');
                // lis.eq(i).removeClass('head-step__text_on');
                // lis.eq(i).removeClass('head-step__text_out');
                // lis.eq(i = ++i % lis.length).addClass('head-step__text_on');
                // lis.eq(i = ++i % lis.length).addClass('head-step__text_out');

                var position = $('.head-step__text_on').index();
                var position2;
                if( position == 0 ) { position2 = 1; } else { position2 = 0; }
                lis.eq(position).removeClass('head-step__text_on');
                lis.eq(position).addClass('head-step__text_out');
                lis.eq(position2).addClass('head-step__text_on');
                setTimeout(function(){
                    lis.eq(position).removeClass('head-step__text_out');
                }, 1000);

                // if( position == 0 ) { 
                //     lis.eq(0).removeClass('home_header_on');
                //     lis.eq(0).addClass('home_header_out');
                //     lis.eq(1).addClass('home_header_on');
                //     setTimeout(function(){
                //         lis.eq(0).removeClass('home_header_out');
                //     }, 1000);
                // } else {
                //     lis.eq(1).removeClass('home_header_on');
                //     lis.eq(1).addClass('home_header_out');
                //     lis.eq(0).addClass('home_header_on');
                //     setTimeout(function(){
                //         lis.eq(1).removeClass('home_header_out');
                //     }, 1000);
                // }



                setTimeout(function() {
                    switchClass(i);
                }, 3500);
            }

            $(window).load(function() {
                switchClass(0);
            });
        });

        if ($('#prePhaseSection').length > 0) {
            $('#prePhaseSection').addClass('activeStep');
            $('#zonesGenres').owlCarousel({
                items: 1,
                navSpeed: 800,
                nav: true
            });
        }

        /**
         * Manage diagnostic page
         */
        $(function () {
            var url = '/diagnostic-2/';

            $('.step__second-style a#femme, .step__second-style a#femme-mobile, .step__second-style a#homme, .step__second-style a#homme-mobile, .step__second-style a#enfant, .step__second-style a#enfant-mobile').on('click', function () {
                let id = $(this).attr('id');
                window.location = url + '?id=' + id; // Redirect to diagnostic page with parameter
            });

            // In diagnostic page, trigger click if there is a parameter
            function getUrlParameter(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            };

            // Trigger the click
            $(document).ready(function () {
                var id = getUrlParameter('id');

                if (id) {
                    var el = $('a#'+id);

                    if (el.length > 0) {
                        el.trigger('click');
                    }
                }
            });
        });


        // document.getElementById ("checkAllTopicCheckBoxes").addEventListener ("click", myFunction, false);



        function getSymptomes(zone_id,genre_id){
            jQuery('.bloc-loading').show();
            jQuery.ajax({
                type: 'POST',   /* Adding Post method*/
                url: ajaxCall.ajaxurl, /*Including ajax file*/
                data: {"action": "getSymptomesByZone", "zone_id": zone_id, "genre_id": genre_id},
                success: function(data){
                    jQuery(".symptomesWrapper").html( data );
                    jQuery('#stepTwo').hide(0,function(){
                        jQuery('#stepThree').show(0, function(){
                            if( jQuery('#symptomesContent').length ){
                                jQuery('#symptomesContent').owlCarousel({
                                    navSpeed: 800,
                                    responsive:{
                                        0:{
                                            items:1,
                                            nav:true
                                        },
                                        768:{
                                            items:3,
                                            nav:true
                                        }
                                    }
                                });
                            }
                        });
                        jQuery('#prePhaseSection.activeStep').removeClass('activeStep');
                        setTimeout(function(){jQuery('.bloc-loading').fadeOut(400);},400);
                    });

                    jQuery("a.symptome").mouseenter(function() {
                        var IdZone = jQuery(this).attr('data-symptome');
                        jQuery('.caption-symptome.active').hide();
                        jQuery('.caption-symptome[data-caption="'+IdZone+'"]').show(0, function(){
                            jQuery(this).addClass('active');
                        });
                    }).mouseleave(function() {
                        var IdZone = jQuery(this).attr('data-symptome');
                        jQuery('.caption-symptome[data-caption="'+IdZone+'"]').hide(0, function(){
                            jQuery(this).removeClass('active');
                        });
                    });
                }
            });
            event.preventDefault();
        }

        $(document).on('click', 'a.module_zone', function(event) {
            var dataZone = $(this).attr('data-zone'),
                dataGenre = $(this).attr('data-genre');            
            getSymptomes(dataZone,dataGenre);
            event.preventDefault();
        });

        function getTraitements(symptome_id, zone_id){
            jQuery('.bloc-loading').show();
            jQuery.ajax({
                type: 'POST',   /* Adding Post method*/
                url: ajaxCall.ajaxurl, /*Including ajax file*/
                data: {"action": "getTraitementsBySymptome", "symptome_id": symptome_id, "zone_id": zone_id },
                success: function(data){
                    jQuery(".traitementsWrapper").html( data );
                    jQuery('#stepThree').hide(0,function(){
                        jQuery('#stepFour').show(0, function(){
                            if( jQuery('#traitementsSlider').length ){
                                jQuery('#traitementsSlider').owlCarousel({
                                    navSpeed: 800,
                                    responsive:{
                                        0:{
                                            items:1,
                                            nav:true
                                        },
                                        768:{
                                            items:3,
                                            nav:true
                                        }
                                    }
                                });
                            }
                        });
                        setTimeout(function(){jQuery('.bloc-loading').fadeOut(400);},400);
                    });
                }
            });
            event.preventDefault();
        }

        $(document).on('click', 'a.module_symptome', function(event) {
            var dataSymptome = $(this).attr('data-symptome'),
                dataZone = $(this).attr('data-zone');            
            getTraitements(dataSymptome,dataZone);
            event.preventDefault();
        });
        
        /* First Step Module Home */
        // $(document).on('click', '.StepGo', function(event) {
        // 	$('#StepAccueil').hide();
        // 	$('#stepOne').fadeIn(400);
        // 	$('#prePhaseSection').addClass('activeStep');
        // 	jQuery('#zonesGenres').owlCarousel({
        // 		items: 1,
        // 		navSpeed: 800,
        // 		nav: true
        // 	});
        // 	event.preventDefault();
        // });

        //Loading Parutions by Magazine ( Cover ) taxonomy ID > AJAX
        $(document).on('click', '#presseCovers a.cover', function(event){
            $.ajax({
                type: 'POST',   // Adding Post method
                url: ajaxCall.ajaxurl, // Including ajax file
                data: {"action": "marchac_get_parutions_by_magazine", "mag_id": $(this).data('cover') },
                success: function(data){
                    if( data == "" )
                        alert('Aucune parution lié a cette couverture !');
                    else{
                        //Traitement
                    }
                }
            });
        });

        /*Loading Zones By Gender ID*/
        $(document).on('click', '.prePhaseSection:not(.step__second-style) a#homme, .prePhaseSection:not(.step__second-style) a#homme-mobile', function(event){
            $('.bloc-loading').show();
            $.ajax({
                type: 'POST',   /* Adding Post method*/
                url: ajaxCall.ajaxurl, /*Including ajax file*/
                data: {"action": "getZonesByGender", "genre_id": 1 },
                success: function(data){
                    $(".zonesWrapper").html( data );
                    $('#stepOne').hide(0,function(){
                        $('#stepTwo').show(0, function(){
                            if( $('#zonesContent').length ){
                                var owlZoneContentHomme = $('#zonesContent');
                                owlZoneContentHomme.owlCarousel({
                                    navSpeed: 800,
                                    responsive:{
                                        0:{
                                            items:1,
                                            nav:true
                                        },
                                        768:{
                                            items:3,
                                            nav:true
                                        }
                                    }
                                });
                                // if( $(window).width() < 768 ){
                                // 	var IDZone = $('#zonesContent .owl-item.active a.zone').attr('data-zone');
                                // 	$('.caption-zone').hide();
                                // 	$('.caption-zone[data-caption="'+IDZone+'"]').show(0,function(){
                                // 		$(this).addClass('active');
                                // 	});

                                // 	owlZoneContentHomme.on('changed.owl.carousel', function(event) {
                                // 		var current = event.item.index;
                                // 		var aIDZone = $('#zonesContent').find(".owl-item").eq(current).find("a.zone").attr('data-zone');
                                // 		$('.caption-zone').hide();
                                // 		$('.caption-zone[data-caption="'+aIDZone+'"]').show(0,function(){
                                // 			$(this).addClass('active');
                                // 		});
                                // 		alert('ID Zone: '+aIDZone);
                                // 	});
                                // }
                            }
                        });
                        setTimeout(function(){$('.bloc-loading').fadeOut(400);},500);
                    });
                    $("a.zone").mouseenter(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone.active').hide();
                        $('.caption-zone[data-caption="'+IdZone+'"]').show(0,function(){
                            $(this).addClass('active');
                        });
                    }).mouseleave(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone[data-caption="'+IdZone+'"]').hide(0,function(){
                            $(this).removeClass('active');
                        });
                    });
                }
            });
            event.preventDefault();
        });

        $(document).on('click', '.prePhaseSection:not(.step__second-style) a#femme, .prePhaseSection:not(.step__second-style) a#femme-mobile', function(event){
            $('.bloc-loading').show();
            $.ajax({
                type: 'POST',   /* Adding Post method*/
                url: ajaxCall.ajaxurl, /*Including ajax file*/
                data: {"action": "getZonesByGender", "genre_id": 2 },
                success: function(data){
                    $(".zonesWrapper").html( data );
                    $('#stepOne').hide(0,function(){
                        $('#stepTwo').show(0, function(){
                            if( $('#zonesContent').length ){
                                $('#zonesContent').owlCarousel({
                                    navSpeed: 800,
                                    responsive:{
                                        0:{
                                            items:1,
                                            nav:true
                                        },
                                        768:{
                                            items:3,
                                            nav:true
                                        }
                                    }
                                });
                            }
                        });
                        setTimeout(function(){$('.bloc-loading').fadeOut(400);},500);
                    });
                    $("a.zone").mouseenter(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone.active').hide();
                        $('.caption-zone[data-caption="'+IdZone+'"]').show(0,function(){
                            $(this).addClass('active');
                        });
                    }).mouseleave(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone[data-caption="'+IdZone+'"]').hide(0,function(){
                            $(this).removeClass('active');
                        });
                    });
                }
            });
            event.preventDefault();
        });

        $(document).on('click', '.prePhaseSection:not(.step__second-style) a#enfant, .prePhaseSection:not(.step__second-style) a#enfant-mobile', function(event){
            $('.bloc-loading').show();
            $.ajax({
                type: 'POST',   /* Adding Post method*/
                url: ajaxCall.ajaxurl, /*Including ajax file*/
                data: {"action": "getZonesByGender", "genre_id": 3 },
                success: function(data){
                    $(".zonesWrapper").html( data );
                    $('#stepOne').hide(0,function(){
                        $('#stepTwo').show(0,function(){
                            if( $('#zonesContent').length ){
                                $('#zonesContent').owlCarousel({
                                    navSpeed: 800,
                                    responsive:{
                                        0:{
                                            items:1,
                                            nav:true
                                        },
                                        768:{
                                            items:3,
                                            nav:true
                                        }
                                    }
                                });
                            }
                        });
                        setTimeout(function(){$('.bloc-loading').fadeOut(400);},500);
                    });
                    $("a.zone").mouseenter(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone.active').hide();
                        $('.caption-zone[data-caption="'+IdZone+'"]').show(0,function(){
                            $(this).addClass('active');
                        });
                    }).mouseleave(function() {
                        var IdZone = $(this).attr('data-zone');
                        $('.caption-zone[data-caption="'+IdZone+'"]').hide(0,function(){
                            $(this).removeClass('active');
                        });
                    });
                }
            });
            event.preventDefault();
        });

        $(document).on('click', 'a.lien-traitement', function(event) {
            var IdTrait = $(this).attr('data-traitement');
            $('.blocTrait.active').removeClass('active');
            $('.blocTrait[data-traitement="'+IdTrait+'"]').addClass('active');

            $('.traitementBlocContent.active').hide();
            $('.traitementBlocContent.active').removeClass('active');
            $('.traitementBlocContent[data-traitement="'+IdTrait+'"]').fadeIn(400, function() {
                $(this).addClass('active');
            });
            // $('.traitementBlocContent[data-traitement="'+IdTrait+'"]').addClass('active');
            event.preventDefault();
        });

        $(document).on('click', '.go-zone', function(event) {
            $('.bloc-loading').fadeIn(400,function(){
                $('#stepFour').hide();
                $('#stepTwo').fadeIn(400, function(){
                    $('.bloc-loading').fadeOut(400);
                });
            });
            event.preventDefault();
        });

        $(document).on('click', '.backStep1', function(event) {
            $('.bloc-loading').fadeIn(400,function(){
                $('#stepOne').hide();
                $('#StepAccueil').fadeIn(400, function(){
                    $('.bloc-loading').fadeOut(400);
                });
            });
            event.preventDefault();
        });

        $(document).on('click', '.backStep2', function(event) {
            $('.bloc-loading').fadeIn(400,function(){
                $('#stepTwo').hide();
                $('#stepOne').fadeIn(400, function(){
                    $('.bloc-loading').fadeOut(400);
                });
            });
            event.preventDefault();
        });

        $(document).on('click', '.backStep3', function(event) {
            $('.bloc-loading').fadeIn(400,function(){
                $('#stepThree').hide();
                $('#stepTwo').fadeIn(400, function(){
                    $('.bloc-loading').fadeOut(400);
                });
            });
            event.preventDefault();
        });

        $(document).on('click', '.backStep4', function(event) {
            $('.bloc-loading').fadeIn(400,function(){
                $('#stepFour').hide();
                $('#stepThree').fadeIn(400, function(){
                    $('.bloc-loading').fadeOut(400);
                });
            });
            event.preventDefault();
        });


        if( $(window).width() > 768 ){
            $(".hover_bloc_cat_holder").mouseenter(function() {
                $(this).find('.text_initial_bloc_cat').stop().hide(0,function() {
                    $(this).parent().find('.text_hover_bloc_cat').fadeIn('0');
                });
            }).mouseleave(function() {
                $(this).find('.text_hover_bloc_cat').stop().hide(0,function() {
                    $(this).parent().find('.text_initial_bloc_cat').fadeIn('0');
                });
            });
        }else{
            $(document).on('click tap', '.hover_bloc_cat_holder_inner', function(event) {
                $('.bloc_cat_home .text_hover_bloc_cat').hide()
                $('.bloc_cat_home .text_initial_bloc_cat').show()
                $(this).find('.text_initial_bloc_cat').stop().hide(0,function() {
                    $(this).parent().find('.text_hover_bloc_cat').fadeIn('0');
                });
                //event.preventDefault();
            });
        }


        $(".bloc-container-hover-lien").mouseenter(function() {
            $(this).find('.bloc-hover-lien').slideDown('400');
        }).mouseleave(function() {
            $(this).find('.bloc-hover-lien').slideUp('400');
        });

        if( $(window).width() < 768 ){
            $(document).on('click', '.bloc-container-hover-lien a.sBtn-gris', function(event) {
                event.preventDefault();
            });
        }



        $('.cliniqueTab').mouseenter(function(event) {
            var positionClinique = $(".cliniqueTab").index(this) + 1;
            $('.cliniqueTab.active').removeClass('active');
            $(this).addClass('active');
            $('.encart-img-clinique.active').fadeOut('400', function() {
                $(this).removeClass('active');
                $('.imgclinique-' + positionClinique).fadeIn('400', function() {
                    $(this).addClass('active');
                });
            });
        });


        if( $('.tabs-home').length ){
            $(".tabs-home .wpb_text_column.wpb_content_element").niceScroll({
                cursorcolor: "#3e3e3e", // change cursor color in hex
                cursoropacitymin: 1, // change opacity when cursor is inactive (scrollabar "hidden" state), range from 1 to 0
                cursoropacitymax: 1, // change opacity when cursor is active (scrollabar "visible" state), range from 1 to 0
                cursorwidth: "3px", // cursor width in pixel (you can also write "5px")
                cursorborder: "none", // css definition for cursor border
                zindex: 9999,
                autohidemode: false,
                background: "#d3d3d3"
            });

            $(document).on('click', '.tabs-home .q_tabs .tabs-nav li a', function(event) {
                $(".tabs-home .wpb_text_column.wpb_content_element").getNiceScroll().resize();
                event.preventDefault();
            });
        }


        /*********************************** Bouton Show More Description ***********************************/
        $(document).on('tap click', '.bouton-more-desc', function(event) {
            event.preventDefault();
            if( $(this).hasClass('open') ){
                $(this).removeClass('open');
                $(this).addClass('onslide');
                $('.more-desc').slideUp('slow');
            }else{
                $(this).addClass('open');
                $(this).addClass('onslide');
                $('.more-desc').slideDown('slow');
                //$('.bloc-img-right-diagnostic').height('100%');
            }
        });

        $(document).on('tap click', '.bouton-more-desc-mobile', function(event) {
            event.preventDefault();
            if( $(this).hasClass('open') ){
                $(this).removeClass('open');
                $(this).addClass('onslide');
                $('.more-desc-mobile').slideUp('slow');
            }else{
                $(this).addClass('open');
                $(this).addClass('onslide');
                $('.more-desc-mobile').slideDown('slow');
                //$('.bloc-img-right-diagnostic').height('100%');
            }
        });

        $(".bouton-more-desc").mouseenter(function() {
            $(this).removeClass('onslide');
        });
        $(".bouton-more-desc-mobile").mouseenter(function() {
            $(this).removeClass('onslide');
        });

        /*********************************** Traitement Schema Zone ***********************************/
        $(document).on('click', '.list-puces-zones li', function(event) {
            var sPosition = $(this).attr('data-zone');
            $('.list-puces-zones li').removeClass('active');
            $('.tabs-zones .q_tabs .tabs-nav li').eq(sPosition - 1).trigger('click');
            $(this).addClass('active');
            $('.bloc-liens-zones .list-lien-zones li.active').removeClass('active');
            $(".bloc-liens-zones .list-lien-zones li[data-lzone='"+sPosition+"']").addClass('active');
            event.preventDefault();
        });

        $(document).on('click', '.tabs-zones .q_tabs .tabs-nav li a', function(event) {
            var sPosition = $(this).parent().index() + 1;
            $('.list-puces-zones li').removeClass('active');
            $(".list-puces-zones li[data-zone='"+sPosition+"']").addClass('active');
            $('.bloc-liens-zones .list-lien-zones li.active').removeClass('active');
            $(".bloc-liens-zones .list-lien-zones li[data-lzone='"+sPosition+"']").addClass('active');
            event.preventDefault();
        });


        /*********************************** Vidéo partie "VOIR LES SYMPTÔMES" page 2ème niveau Diagnostic ***********************************/
        if( $('#videoPlayer').length ){
            loadPlayer();
        }


        /*********************************** Button "Regarder la vidéo explicative" page 3ème niveau Diagnostic ***********************************/
        $(document).on('click', '.btn-see-video', function(event) {
            $('.qode_video_image').trigger('click');
            event.preventDefault();
        });


        function MoveDescPopup(){
            if( $('.pp_description').length ){
                $('.pp_description').insertBefore('.pp_details');
                clearInterval(myVar);
            }
        }
        if( $('body.parent-pageid-120').length ){
            var myVar = setInterval(function(){ MoveDescPopup() }, 10);
        }


        $(document).on('click', '.bloc-txt-faq a', function(event) {
            event.preventDefault();
            var sLien = $(this).attr('href');
            window.location.href = sLien+"#faq";
        });


        // function changeText(content1,content2,speed){
        // 	var stext=content1.data("text");
        // 	var sContent=stext.split("");
        // 	var i=0;
        // 	function show(){
        // 		if(i<sContent.length){
        // 			content2.append(sContent[i]);
        // 			i=i+1;
        // 		}
        // 		if(i==sContent.length){
        // 			$("a.StepGo").empty();
        // 			i=0;
        // 		}
        // 	}
        // 	var sTimer=setInterval(show,speed);
        // 	clearInterval(sTimer);
        // }

        if( $("a.StepGo").length ){
            $('a.StepGo').css('opacity', '1');
            $('a.StepGo').textillate({ in: { effect: 'fadeIn' } });
            // changeText($("a.StepGo"),$("a.StepGo"),150);
        }

        $(document).on('click', '.tabs-cabinet.tabs-infos-utiles .q_tabs .tabs-nav li a', function(event) {
            $(window).trigger('resize');
            event.preventDefault();
        });

        $(document).on('click', '.q_tabs.vertical.left .tabs-nav li a', function(event) {
            if( $('.bx-wrapper').length ){
                setTimeout(function(){
                    $('.bx-controls-direction').css('opacity', '0');
                }, 1000);
            }
            event.preventDefault();
        });

        if( $('.container-form-contact').length ){
            //hide all inputs except the first one
            $('div.hide').not(':eq(0)').hide();

            //functionality for add-file link
            $('a.add_file').on('click', function(e){
                //show by click the first one from hidden inputs
                $('div.hide:not(:visible):first').show('slow');
                e.preventDefault();
            });

            //functionality for del-file link
            $('a.del_file').on('click', function(e){
                //var init
                var input_parent = $(this).parent().parent();
                var input_wrap = input_parent.find('span');
                var input_div_file = input_parent.find('.file-name');
                //reset field value
                input_wrap.html(input_wrap.html());
                input_div_file.html('Aucun fichier choisi.');
                //hide by click
                input_parent.hide('slow');
                e.preventDefault();
            });

            //functionality for del-file link
            $('a.sup_file').on('click', function(e){
                //var init
                var input_parent = $(this).parent().parent();
                var input_wrap = input_parent.find('span');
                var input_div_file = input_parent.find('.file-name');
                //reset field value
                input_wrap.html(input_wrap.html());
                input_div_file.html('Aucun fichier choisi.');
                //hide by click
                //input_parent.hide('slow');
                // $('.add_file').addClass('not-active');
                $(this).hide();
                e.preventDefault();
            });

            $(document).on('change','.wpcf7 .list-input-file input[type="file"]',function(event) {
                var filename = event.target.files[0].name;
                //var filename = $(this).val();
                //alert('name: '+filename);
                $(this).closest('.file-upload').find('.file-name').html(filename);
                // if ($(this).val()) {
                // }else{
                // 	alert('tttt');
                // }
            });

            $(document).on('change', 'input#file-1', function(event) {
                event.preventDefault();
                $('.add_file').removeClass('not-active');
                $('.sup_file').fadeIn(400);
            });
        }

        var sUrlDiag = document.location.toString();
        sUrlDiag = sUrlDiag.split('#')[1];
        if( sUrlDiag != undefined && sUrlDiag != '' && sUrlDiag == "votre-diagnositc" && $('#prePhaseSection').length ){
            $('a.StepGo').trigger('click');
            setTimeout(function() {
                $('html, body').animate( { scrollTop: $('#prePhaseSection').offset().top}, 1500 );
            }, 500);
        }

        // if( sUrlDiag != undefined && sUrlDiag != '' && sUrlDiag == "faq" && $('.bloc__faq').length ){
        //     setTimeout(function() {
        //         $('html, body').animate( { scrollTop: $('.bloc__faq').position().top + 70}, 800 );
        //     }, 500);
        // }
    });

    $(window).load(function() {
        //$('.bloc-img-right-diagnostic').height($('.bloc-txt-left-diagnostic').height() + 115);
        /*********************
         SCROLL PAGE FAQ
         **********************/
        var sUrl = document.location.toString();
        sUrl = sUrl.split('#')[1];
        if( sUrl != undefined && sUrl != '' && sUrl == "faq" && $('.bloc__faq').length ){
            setTimeout(function() {
                $('html, body').animate( { scrollTop: $('.bloc__faq').position().top + 50}, 1500 );
            }, 500);
        }
        // if( sUrl != undefined && sUrl != '' && sUrl == "votre-diagnositc" && $('#prePhaseSection').length ){
        // 	$('a.StepGo').trigger('click');
        // 	setTimeout(function() {
        // 		$('html, body').animate( { scrollTop: $('#prePhaseSection').offset().top}, 1500 );
        // 	}, 500);
        // }
        if( sUrl != undefined && sUrl != '' && sUrl == "homme" ){
            $('.photo-homme').css('display', 'block');
            $('.photo-femme').css('display', 'none');
            $('.bg-homme').addClass('bg-homme-active');
        }else{
            if( $('.photo-homme').length ){
                $('.photo-femme').css('display', 'block');
                $('.bg-homme').removeClass('bg-homme');
            }
        }


        /*********************************** Slider Avant/Après avec les légendes ***********************************/
        if( ($('.img-slider .bxslider').length == 0) && ($('.desc-slider .bxslider').length == 0) && ($('.bxslider').length) ){
            $('.bxslider').bxSlider({
                adaptiveHeight: true
            });
        }

        if( $('.img-slider .bxslider').length ){
            var sliderImg = $('.img-slider .bxslider').bxSlider({
                adaptiveHeight: true,
                onSlideBefore: function($slideElement, oldIndex, newIndex){
                    sliderDesc.goToSlide(newIndex);
                }
            });
        }

        if( $('.desc-slider .bxslider').length ){
            var sliderDesc = $('.desc-slider .bxslider').bxSlider({
                adaptiveHeight: true,
                onSlideBefore: function($slideElement, oldIndex, newIndex){
                    sliderImg.goToSlide(newIndex);
                }
            });
        }

        $(document).on('click', '.img-slider .bx-next', function(event) {
            sliderImg.goToNextSlide();
            sliderDesc.goToNextSlide();
            event.preventDefault();
        });

        $(document).on('click', '.img-slider .bx-prev', function(event) {
            sliderImg.goToPrevSlide();
            sliderDesc.goToPrevSlide();
            event.preventDefault();
        });

        $(document).on('click', '.bx-pager-link', function(event) {
            var currentSlide = $(this).attr('data-slide-index');
            sliderImg.goToSlide(currentSlide)
            event.preventDefault();
        });

        $(document).on('click', 'body.home .diagnositc-footer-top a', function(event) {
            if( $('#StepAccueil').is(':visible') ){
                $('a.StepGo').trigger('click');
            }
            $('html, body').animate( { scrollTop: $('#prePhaseSection').offset().top}, 1500 );
            event.preventDefault();
        });

        $(document).on('click', '.left_content_ancre ul li', function(event) {
            var sData = $(this).attr('data-ancre');
            $('.left_content_ancre ul li.current').removeClass('current');
            $('.left_content_ancre ul li[data-ancre="'+sData+'"]').addClass('current');
            $('html, body').animate({scrollTop: $('.'+sData).offset().top - 25},1000);
            event.preventDefault();
        });
        $(window).scroll(function (event) {
            if( $('.left_content_ancre').length ) {
                var scroll = $(window).scrollTop();
                if (scroll >= $('.left_content_ancre').offset().top) {
                    $(".left_content_ancre").addClass('fixed_box');
                } else {
                    $(".left_content_ancre").removeClass('fixed_box');
                }
            }

            if( $('.anchorBlock__links').length && $(window).width() < 1000 ) {
                var parent = document.querySelector('.anchorBlock__links').parentElement;
                while (parent) {
                    const hasOverflow = getComputedStyle(parent).overflow;
                    if(hasOverflow !== 'visible') {
                        parent.style.overflow = "visible";
                    }
                    parent = parent.parentElement;
                }
            }

            if( $('.section_link_to_faq').length ) {
                var parent = document.querySelector('.section_link_to_faq').parentElement;
                while (parent) {
                    const hasOverflow = getComputedStyle(parent).overflow;
                    if(hasOverflow !== 'visible') {
                        parent.style.overflow = "visible";
                    }
                    parent = parent.parentElement;
                }   
            }

        });

        if( $('.all__opened').length ) {
            $('.all__opened.accordion .ui-accordion-header').trigger('click')
        }



        // Popup Close Cabinet
        // if( jQuery('.popup_closeinfos').length ) {
        //     if( !jQuery.cookie('PopupCabinetClosed') ) {
        //         setTimeout(function(){
        //             jQuery('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
        //         }, 1000);
        //     }
        // }

        // $(document).on('click', '.popup_closeinfos__close', function(event) {
        //   $('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
        //   var date = new Date();
        //   var minutes = 60;
        //   date.setTime(date.getTime() + (minutes * 60 * 1000));

        //   jQuery.cookie('PopupCabinetClosed', Math.floor(new Date().getTime() / 1000)+(86400*7), jQuery.extend({}, { expires: date, path: '/' }) );
        //   event.preventDefault();
        // });


        // Redirect links block
        const $redirectLinksBlock = $('.redirect-links--block');
        if ($redirectLinksBlock.length) {
            // $redirectLinksBlock.find('a').doubleTapToGo();
            $redirectLinksBlock.find('a')
                .on('mouseenter', function () {
                   $(this).find('div').first().stop().slideDown(function(){
                    $(this).css('height', 'auto');
                   });
                })
                .on('mouseleave', function () {
                    $(this).find('div').first().stop().slideUp(function(){
                        $(this).css('height', 'auto');
                       });
                });
        }


        $(document).on('click', '.btn_show_comment span', function(event) {
            $(this).closest('.comment-list').children('li').slideDown(400, function() {
                $('.overlay_comment, .btn_show_comment').fadeOut();
            })
            event.preventDefault();
        });


        $(document).on('click', '.ancre_to_doctolib', function (event) {
            $('html, body').animate({scrollTop: $('#rendez-vous').offset().top - 193}, 800);
           event.preventDefault(); 
        });


        // Boutton : Accéder aux informations utiles
        $(document).on('click', '.got_to_infos', function(event) {
            $('html, body').animate({scrollTop: $('#find-office').offset().top - 100}, 800);
            event.preventDefault();
        });


        // Home > Mes autres spécialités
        $(document).on('click','.specialite__encarts .vc_column_container', function () {
            var sLink = $(this).find('a').attr('href');
            window.location.href = sLink;
        });

        // Bloc FAQ
        $(document).on('click','.other_symptomes__bloc.bloc__faq a', function (event) {
            event.preventDefault();
        });

        // Page FAQ
        $(document).on('click', '.bloc_link_to_faq a', function(event) {
            var iPosition = $(this).attr('data-link');
            $('html, body').animate({scrollTop: $('#' + iPosition).offset().top - 250}, 800);
            event.preventDefault();
        });


        if( $('body.home').length ) {
            var controller = new ScrollMagic.Controller();

            /***************************************************************** 
            #########   Animation show all bloc white class ".animated-show"
            ******************************************************************/
            var $animatedBloc = $('.animated-show'),
                $animateBloc = $('.animate_bloc');

            $animatedBloc.each(function() {
                new ScrollMagic.Scene({
                    triggerElement: this,
                    reverse: false,
                    triggerHook: 0.9
                })
                .setClassToggle(this, 'animted_on')
                // .addIndicators()
                .addTo(controller);
            });

            $animateBloc.each(function() {
                new ScrollMagic.Scene({
                    triggerElement: this,
                    reverse: false,
                    triggerHook: 0.9
                })
                .setClassToggle(this, 'animate_on')
                // .addIndicators()
                .addTo(controller);
            });


            /***************************************************************** 
            #########   Hero Animation 
            ******************************************************************/
            var heroTl = new TimelineMax(),
            hero_h1_div = $('#section_hero h1'),
            hero_h1_strong = $('#section_hero p, #section_hero a');

            heroTl
                .to(hero_h1_div, 0.8, {autoAlpha: 1, y: '0', ease:Power1.easeNone})
                .to(hero_h1_strong, 0.8, {autoAlpha: 1, y: '0', ease:Power1.easeNone})

            // Scene Hero HomePage
            var scene_hero = new ScrollMagic.Scene({
                triggerElement: '#section_hero',
                reverse: false
            })
            .setTween(heroTl)
            .addTo(controller);


            /***************************************************************** 
            #########   Qui suis-je? Animation 
            ******************************************************************/
            var aboutTl = new TimelineMax(),
                about_title = $('.home_bloc_biographie h1'),
                about_img   = $('#section_biographie .vc_single_image-wrapper'),
                about_list  = $('.redirect-links--block .item_link_bloc'),
                about_link  = $('.link_home_arrow');

            aboutTl
                .to(about_title,0.6,{autoAlpha: 1, y: '0', ease:Power1.easeOut})
                .set(about_img, {className:"+=animated"})
                .staggerTo(about_list, 0.6, {autoAlpha: 1, y: '0', scale:1, ease:Power1.easeOut},0.15)
                .to(about_link,0.5,{autoAlpha: 1, y: '0', ease:Power1.easeOut})

            var scene_about = new ScrollMagic.Scene({
                triggerElement: '#section_biographie',
                reverse: false,
                triggerHook: 0.7
            })
            .setTween(aboutTl)
            .addTo(controller);
        }


/*
**  Show/Hide Mobile menu
*/
$(".mobile_menu_button > span").on('tap click', function(e){
    e.preventDefault();
    $(".mobile_menu_button").toggleClass("active");
    $(".mobile_menu > ul").toggle(200);
    $('html, body').toggleClass('noScroll');
    //  $(".mobile_menu > ul").slideUp(200);
//       if ($(".mobile_menu > ul").is(":visible")){
//        $(".mobile_menu_button").addClass("active");
    //  $(".mobile_menu > ul").slideUp(200);
    // } else {
    //  $(".mobile_menu_button").removeClass("active");
    //  $(".mobile_menu > ul").slideDown(200);
    // }
});

$(".mobile_menu span.mobile_arrow").on('tap click', function(e){
    e.preventDefault();

    if ($(this).closest('li.has_sub').find("> ul.sub_menu").is(":visible")){
        $(this).closest('li.has_sub').find("> ul.sub_menu").eq(0).slideUp(200);
        $(this).closest('li.has_sub').removeClass('open_sub');
    } else {
        $(this).closest('li.has_sub').addClass('open_sub');
        $(this).closest('li.has_sub').find("> ul.sub_menu").eq(0).slideDown(200);
    }
});

// $(".mobile_menu > ul > li.has_sub > ul.sub_menu > li.has_sub > span.mobile_arrow, .mobile_menu > ul > li.has_sub > ul.sub_menu > li.has_sub > h3, .mobile_menu > ul > li.has_sub > ul.sub_menu > li.has_sub > a[href*='#']").on('tap click', function(e){
//     e.preventDefault();

//     alert('test2');

//     if ($(this).parent().find("ul.sub_menu").is(":visible")){
//         $(this).parent().find("ul.sub_menu").eq(0).slideUp(200);
//         $(this).parent().removeClass('open_sub');
//     } else {
//         $(this).parent().addClass('open_sub');
//         $(this).parent().find("ul.sub_menu").eq(0).slideDown(200);
//     }
// });

// $(".mobile_menu ul li > a, .q_logo a").on('click', function(){
//     if(($(this).attr('href') !== "http://#") && ($(this).attr('href') !== "#")){
//         $(".mobile_menu > ul").slideUp();
//     }
// });


window.onscroll = function(ev) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        $('.bloc_widget_fixed').addClass('in_bottom');
    } else {
        $('.bloc_widget_fixed').removeClass('in_bottom');
    }
};

    });

} )( jQuery );



/** anchorBlock **/
if ( document.querySelector('.anchorBlock')) {
    new GumShoe('.anchorBlock__links a', {
        reflow: true,
        nested: true,
        nestedClass: 'active-parent',
        offset: () => {
            let defaultOffset = 193,
                $adminBar = document.querySelector('#wpadminbar'),
                adminBarHeight = ($adminBar ? $adminBar.getBoundingClientRect().height : 0);

            return defaultOffset + adminBarHeight;
        }
    });
}


/***** Video Symptomes ******/
function loadPlayer() {
    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = function() {
            onYouTubePlayer();
        };

    } else {
        onYouTubePlayer();
    }
}

var player;

function onYouTubePlayer() {
    player = new YT.Player('videoPlayer', {
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onYTPlayerStateChange
        }
    });
}


function onPlayerReady(event) {
    var playButton = document.getElementById("button-play");
    playButton.addEventListener("click", function() {
        console.log('Ready');
        document.getElementById("coverVideo").style.display='none';
        player.playVideo();
    });
}

function onYTPlayerStateChange(event){
    if(event.data === YT.PlayerState.ENDED) {
        document.getElementById("coverVideo").style.display='block';
    }
}